@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {

    html {
        font-family: 'Roboto', sans-serif;
        min-height: 100%;
    }

    body {
        background: linear-gradient(180deg, rgba(0,0,0) 80%, rgba(255,62,65,0.8436624649859944) 100%);
        min-height: 100vh;
    }

    .background-acervo {
        background: linear-gradient(180deg, rgba(0,0,0) 5%, rgba(255,62,65,0.8436624649859944) 100%);
        min-height: 100vh;
    }

    .background-sobre {
        background: rgba(0,0,0);
        min-height: 100vh;
    }

    .sick-font {
        font-family: 'Chakra Petch', sans-serif;
    }

}

@property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}

@keyframes counter {
    from {
        --num: 0;
    }
    to {
        --num: 2000;
    }
}    

